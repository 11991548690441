import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@mui/material';
import { SEO } from '../../components/Seo';

import ContentContainer from '../../styles/content-container';
import Layout from '../../components/Layout';
import '../../../static/content.css';

const width = ['97%', '95%', '55%', '55%', '55%'];

export default function Template({
  data, 
}) {
  const { markdownRemark } = data; 
  const { frontmatter, html } = markdownRemark;

  const { tags } = frontmatter;
  const postdate = `Post Date  ${frontmatter.date}`;

  return (
    <Layout>
      <ContentContainer sx={{ textAlign: 'center', marginBottom: '64px', width }}>
        <Typography variant="h1">{frontmatter.title}</Typography>
      </ContentContainer>
      <ContentContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '32px',
          alignItems: 'center',
          width,
        }}
      >
      </ContentContainer>
      <ContentContainer sx={{ marginBottom: '12px', width }}>
        <Typography variant="cation" sx={{ fontWeight: 300 }}>
          {postdate}
        </Typography>
      </ContentContainer>
      <ContentContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: '24px',
          width,
          gap: '8px',
        }}
      >
        {tags !== null &&
          tags.map(
            (tag) =>
              (
                <Typography
                  variant="tag"
                  sx={{
                    border: '1px solid #FD6F3E',
                    borderRadius: '6px',
                    padding: '3px 8px',
                    display: 'inline-block',
                    textAlign: 'center',
                    marginBottom: '8px',
                  }}
                >
                  {tag}
                </Typography>
              ),
          )}
      </ContentContainer>
      <ContentContainer sx={{ marginBottom: '8%', width }} className="author-content">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </ContentContainer>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
      }
    }
  }
`;
